import React, { Component } from 'react'
import TextField from '../common/TextField'
import { withStyles } from '@material-ui/core/styles'
import Notifications from 'react-notification-system-redux'
import Grid from '@material-ui/core/Grid'
import Button from '../common/Button'
import { ServerInfo } from '../../Actions/serverSetting'
import Loader from './../../Layout/Loader'
import isEqual from 'react-fast-compare'
import ChildBranding from './copyAttributes'
import CustomDialog from '../common/Dialog'

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  },
  button: {
    margin: theme.spacing(1)
  },
  paper: {
    padding: theme.spacing(1) * 2
  },
  bigAvatar: {
    margin: 10,
    width: 120,
    height: 100,
    borderRadius: '2%'
  }
})

class branding extends Component {
  constructor () {
    super()
    this.state = {
      isVisableAddBtn: false,
      itemAttributes: {},
      vaildelogoFile: false,
      vaildefavIconFile: false,
      vaildeLogInPageImageFile: false,
      imagesData: [],
      defaultOptions: [],
      apiResponse: false,
      childAccModal: false,
      form: {
        serviceTitle: '',
        footerText: '',
        privacyPolicyLink: '',
        termsLink: '',
        hostUrl: '',
        adminUrl: '',
        serverIp: '',
        favIcon: '',
        logo: '',
        logInPageImage: ''
      }
    }
    this.handleChange = this.handleChange.bind(this)
    this.formSubmit = this.formSubmit.bind(this)
    this.finalFormSubmit = this.finalFormSubmit.bind(this)
    this.formReset = this.formReset.bind(this)
    this.checkRequiredFields = this.checkRequiredFields.bind(this)
  }
  componentWillMount () {
    if (this.props.updateOtherServerSetting && this.props.ServerSetting) {
      //fetch call for accounts list
      fetch(
        `/api/accounts/all?accountId=${this.props.ServerSetting.id}&all=false&limit=100`
      ).then(response => {
        if (response.ok) {
          response.json().then(res => {
            if(res.status ==='success'){
              let accounts = res.data
              let dataValue = []
              if (accounts.data && accounts.data.length) {
                accounts.data.map(item => {
                  dataValue.push({
                    ...item,
                    label: item.label || item.name,
                    value: item.id
                  })
                })
              }
              this.setState({
                defaultOptions: dataValue,
                apiResponse: true
              })
            }
            else if(res?.statusCode === '440'){
              window.location.replace('/login')
            }
            else if(res.statusCode){
              var err = res?.message.split(':')
              err[1] =err[1].replace(')', "")
              this.props.dispatch(
                Notifications.error({
                  message: this.props.translate(err[1]),
                  autoDismiss: 5,
                  location: this.props.location
                })
              )
            }
          })
        } else {
          this.setState({
            apiResponse: true
          })
          throw response
        }
      })  
      .catch(e => {
        this.props.dispatch(Notifications.error({
          message: 'somethingWentWrong',
          autoDismiss: 5
        }))
      })
    } else {
      this.setState({
        apiResponse: true
      })
    }
    let data = this.setFormData(this.props.ServerSetting)
    this.setState({
      form: data
    })
  }
  formReset () {
    let data = this.setFormData(this.props.ServerSetting)
    this.setState({
      form: data,
      isVisableAddBtn: false
    })
  }
  setFormData = data => {
    let formData = {
      serviceTitle: data?.attributes?.serviceTitle || '',
      footerText: data?.attributes?.footerText || '',
      privacyPolicyLink: data?.attributes?.privacyPolicyLink || '',
      termsLink: data?.attributes?.termsLink || '',
      hostUrl: data?.attributes?.hostUrl || '',
      adminUrl: data?.attributes?.adminUrl || '',
      favIcon: data?.attributes?.favIcon || '',
      logo: data?.attributes?.logo || '',
      logInPageImage: data?.attributes?.logInPageImage || '',
      serverIp: data?.attributes?.serverIp || ''
    }
    return formData
  }
  submitDataPepration = () => {
    let { itemAttributes, form } = this.state
    if (form.serviceTitle) {
      let serviceTitle = form.serviceTitle
      itemAttributes = { ...itemAttributes, serviceTitle }
    }
    if (form.footerText) {
      let footerText = form.footerText
      itemAttributes = { ...itemAttributes, footerText }
    }
    if (form.privacyPolicyLink) {
      let privacyPolicyLink = form.privacyPolicyLink
      itemAttributes = { ...itemAttributes, privacyPolicyLink }
    }
    if (form.termsLink) {
      let termsLink = form.termsLink
      itemAttributes = { ...itemAttributes, termsLink }
    }
    if (form.hostUrl) {
      let hostUrl = form.hostUrl
      itemAttributes = { ...itemAttributes, hostUrl }
    }
    if (form.adminUrl) {
      let adminUrl = form.adminUrl
      itemAttributes = { ...itemAttributes, adminUrl }
    }
    if (form.favIcon) {
      let favIcon = form.favIcon
      itemAttributes = { ...itemAttributes, favIcon }
    }
    if (form.logo) {
      let logo = form.logo
      itemAttributes = { ...itemAttributes, logo }
    }
    if (form.logInPageImage) {
      let logInPageImage = form.logInPageImage
      itemAttributes = { ...itemAttributes, logInPageImage }
    }
    if (form.serverIp) {
      let serverIp = form.serverIp
      itemAttributes = { ...itemAttributes, serverIp }
    }
    return itemAttributes
  }
  uploadLogoImage = async (name, e) => {
    let local = process.env.REACT_APP_SERVER_LOCAL ;

    if(local === 'true'){

    let files = e.target.files
  
    let id = this.props.userUpdate ? this.props.selectedItem.id : this.props.logInUser.id
    const response = await fetch(`/api/uploads/public/${id}`, {
      method: 'POST',
      body: files[0],
    });
    if (response.ok) {
      const responseData = await response.json(); // Parse JSON response
      const imageName = responseData.data.image;
      this.setState({
        form : {
          ...this.state.form,
          [name]:responseData.data.image
            // attributes : {
            //     ...this.state.form.attributes,
            // }
        }
      },()=>{this.checkRequiredFields()})
    }else{
      throw Error(await response.text());

    }
  }else{
    let files = e.target.files
    let imgData = ''
    let reader = new FileReader()
    if (files && files[0]) {
      reader.readAsDataURL(files[0])
      reader.onload = e => {
        imgData = e.target.result
        if (imgData.split(':')[1].split('/')[0] === 'image') {
          let link = this.state.form.logo
          if (name === 'favIcon') {
            link = this.state.form.favIcon
          }
          if (name === 'logInPageImage') {
            link = this.state.form.logInPageImage
          }
          let obj = {
            name: `${this.props.logInUser.name.replace(/ +/g, '') +
              '_' +
              new Date().getTime()}`,
            contentType: `${files[0].type}`,
            oldUrl: `${name === link}`,
            data: `${imgData.split('base64')[1].substring(1)}`
          }
          let { imagesData } = this.state
          if (imagesData && imagesData.length) {
            imagesData.map((item, index) => {
              if (name === item.key) {
                imagesData.splice(index, 1)
              }
            })
          }
          imagesData.push({ key: name, obj })
          this.setState(
            {
              imagesData: imagesData
            },
            () => this.checkRequiredFields()
          )
        } else {
          this.props.dispatch(
            Notifications.error({
              message: this.props.translate('onlyAcceptImage'),
              autoDismiss: 5,
              location: this.props.location
            })
          )
        }
      }
    } else {
      let { imagesData } = this.state
      if (imagesData && imagesData.length) {
        imagesData.map((item, index) => {
          if (name === item.key) {
            imagesData.splice(index, 1)
          }
        })
      }
      this.setState(
        {
          imagesData: imagesData
        },
        () => this.checkRequiredFields()
      )
      this.props.dispatch(
        Notifications.error({
          message: this.props.translate('youSelecteEmpty'),
          autoDismiss: 5,
          location: this.props.location
        })
      )
    }
  }
  }




  uploadImage = (name, e) => {
    let files = e.target.files
    let imgData = ''
    let reader = new FileReader()
    if (files && files[0]) {
      reader.readAsDataURL(files[0])
      reader.onload = e => {
        imgData = e.target.result
        if (imgData.split(':')[1].split('/')[0] === 'image') {
          let link = this.state.form.logo
          if (name === 'favIcon') {
            link = this.state.form.favIcon
          }
          if (name === 'logInPageImage') {
            link = this.state.form.logInPageImage
          }
          let obj = {
            name: `${this.props.logInUser.name.replace(/ +/g, '') +
              '_' +
              new Date().getTime()}`,
            contentType: `${files[0].type}`,
            oldUrl: `${name === link}`,
            data: `${imgData.split('base64')[1].substring(1)}`
          }
          let { imagesData } = this.state
          if (imagesData && imagesData.length) {
            imagesData.map((item, index) => {
              if (name === item.key) {
                imagesData.splice(index, 1)
              }
            })
          }
          imagesData.push({ key: name, obj })
          this.setState(
            {
              imagesData: imagesData
            },
            () => this.checkRequiredFields()
          )
        } else {
          this.props.dispatch(
            Notifications.error({
              message: this.props.translate('onlyAcceptImage'),
              autoDismiss: 5,
              location: this.props.location
            })
          )
        }
      }
    } else {
      let { imagesData } = this.state
      if (imagesData && imagesData.length) {
        imagesData.map((item, index) => {
          if (name === item.key) {
            imagesData.splice(index, 1)
          }
        })
      }
      this.setState(
        {
          imagesData: imagesData
        },
        () => this.checkRequiredFields()
      )
      this.props.dispatch(
        Notifications.error({
          message: this.props.translate('youSelecteEmpty'),
          autoDismiss: 5,
          location: this.props.location
        })
      )
    }
  }
  formSubmit () {
    let { imagesData } = this.state
    let id = this.props.userUpdate ? this.props.selectedItem.id : this.props.logInUser.id
    if (imagesData.length) {
      let callcount = 0
      this.setState({isVisableAddBtn:false},()=>{
      imagesData.map(item => {
        let obj = item.obj
        // fetch(`/api/uploads/entitytype=labeling/entityid=${id}`, {
          fetch(`/api/uploads`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            ...obj
          })
        }).then(response => {
          if (response.ok) {
            response.json().then(publicUrl => {
              if(publicUrl.status ==='success'){
                callcount = callcount + 1
                this.setState(
                  {
                    form: {
                      ...this.state.form,
                      [item.key]: publicUrl.data&&publicUrl.data.publicUrl
                    }
                  },
                  () => {
                    if (callcount === imagesData.length) {
                      this.finalFormSubmit()
                    }
                  }
                )
              }
              else if(publicUrl?.statusCode === '440'){
                window.location.replace('/login')
              }
              else if(publicUrl?.statusCode){
                var err = publicUrl?.message.split(':')
                err[1] =err[1].replace(')', "")
                this.props.dispatch(
                  Notifications.error({
                    message: this.props.translate(err[1]),
                    autoDismiss: 5,
                    location: this.props.location
                  })
                )
              }
            })
          }
          })
          .catch(e => {
            this.props.dispatch(Notifications.error({
              message: 'somethingWentWrong',
              autoDismiss: 5
            }))
          })
      })
    })
    } else {
      this.finalFormSubmit()
    }
  }
  finalFormSubmit () {
    let attriubtes = this.submitDataPepration()
    let { ServerSetting } = this.props
    // delete this.props.ServerSetting.attributes.serverip
    let obj = {}
    let apiCall = '/api/accounts/'
    ServerSetting.attributes = { ...ServerSetting.attributes, ...attriubtes }
    if (this.props.updateOtherServerSetting) {
      let id = []
      obj = {
        ids: [],
        attributes: {
          ...ServerSetting.attributes,
          ...attriubtes,
          labeling: true
        },
        updateRequest: true,
        copyMode: 'withOutLabeling'
      }
      apiCall = '/api/accounts/labeling/'
    } else {
      obj = ServerSetting
    }
    // this.setState({isVisableAddBtn:false},()=>{
    fetch(`${apiCall + ServerSetting.id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        ...obj
      })
    })
      .then(response => {
          response.json().then(res => {
            let server = res.data
            if(res.status === 'success'){
              if (apiCall === '/api/accounts/') {
                this.apiResponse(ServerSetting, server)
              } 
              else {
                if (res.status === 'success') {
                this.apiResponse(ServerSetting, server)
                }
              }
              // this.setState({isVisableAddBtn:true})
            }
            else if(res?.statusCode === '440'){
              window.location.replace('/login')
            }
            else if(res.statusCode){
              var err = res?.message.split(':')
              err[1] =err[1].replace(')', "")
              this.props.dispatch(
                Notifications.error({
                  message: this.props.translate(err[1]),
                  autoDismiss: 5,
                  location: this.props.location
                })
              )
            }
          })
      })
      .catch(e => {
        this.props.dispatch(
          Notifications.error({
            message: this.props.translate('somthingWentWrongMessage'),
            autoDismiss: 5,
            location: this.props.location
          })
        )
      })
    // })
  }
  apiResponse = (ServerSetting, server) => {
    if (this.props.updateOtherServerSetting) {
      this.props.updateSelectedAccount(ServerSetting)
    } else {
      this.props.dispatch(ServerInfo(ServerSetting))
      let domElements = document.getElementById('favicon')
      if (
        ServerSetting &&
        ServerSetting.attributes &&
        server.attributes.favIcon &&
        domElements
      ) {
        domElements.setAttribute('href', ServerSetting.attributes.favIcon)
      }
      let domElements1 = document.getElementById('header-logo')
      if (
        ServerSetting &&
        ServerSetting.attributes &&
        server.attributes.logo &&
        domElements1
      ) {
        domElements1.setAttribute('src', ServerSetting.attributes.logo)
      }
    }
    this.props.dispatch(
      Notifications.success({
        message: this.props.translate('brandingInfoIsUpdated'),
        autoDismiss: 5,
        location: this.props.location
      })
    )
    this.setState({
      isVisableAddBtn: false,
      imagesData: []
    })
  }

  handleChange (name, event) {
    if (event) {
      const { target } = event
      if (target.files && target.files.length) {
        this.handleFile(name, event)
      } else {
        this.setState(
          {
            form: {
              ...this.state.form,
              [name]: target.value
            }
          },
          () => this.checkRequiredFields()
        )
      }
    }
  }
  handleFile = (name, event) => {
    let file = event.target.files[0]
    if (file) {
      let reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        if (file.type.includes('image')) {
          if (name === 'logo') {
            this.setState(
              {
                form: {
                  ...this.state.form,
                  [name]: reader.result
                },
                vaildelogoFile: false
              },
              () => this.checkRequiredFields()
            )
          } else if (name === 'logInPageImage') {
            this.setState(
              {
                form: {
                  ...this.state.form,
                  [name]: reader.result
                },
                vaildeLogInPageImageFile: false
              },
              () => this.checkRequiredFields()
            )
          } else {
            this.setState(
              {
                form: {
                  ...this.state.form,
                  [name]: reader.result
                },
                vaildefavIconFile: false
              },
              () => this.checkRequiredFields()
            )
          }
        } else {
          if (name === 'logo') {
            this.setState(
              {
                form: {
                  ...this.state.form,
                  [name]: ''
                },
                vaildelogoFile: true
              },
              () => this.checkRequiredFields()
            )
          } else if (name === 'logInPageImage') {
            this.setState(
              {
                form: {
                  ...this.state.form,
                  [name]: ''
                },
                vaildeLogInPageImageFile: true
              },
              () => this.checkRequiredFields()
            )
          } else {
            this.setState(
              {
                form: {
                  ...this.state.form,
                  [name]: ''
                },
                vaildefavIconFile: true
              },
              () => this.checkRequiredFields()
            )
          }
        }
        reader.onerror = error => {
          console.log('Error: ', error)
        }
      }
    }
  }

  checkRequiredFields () {
    if(this.props.ServerSetting){
    let {
      serviceTitle,
      footerText,
      privacyPolicyLink,
      termsLink,
      adminUrl,
      hostUrl,
      serverIp,
      favIcon,
      logo,
      logInPageImage
    } = this.props.ServerSetting.attributes
    let { imagesData } = this.state

    if (!serviceTitle) {
      serviceTitle = ''
    }
    if (!footerText) {
      footerText = ''
    }
    if (!privacyPolicyLink) {
      privacyPolicyLink = ''
    }
    if (!termsLink) {
      termsLink = ''
    }
    if (!hostUrl) {
      hostUrl = ''
    }
    if (!adminUrl) {
      adminUrl = ''
    }
    if (!favIcon) {
      favIcon = ''
    }
    if (!logo) {
      logo = ''
    }
    if (!logInPageImage) {
      logInPageImage = ''
    }
    if (!serverIp) {
      serverIp = ''
    }

    let obj = {
      serviceTitle,
      footerText,
      privacyPolicyLink,
      termsLink,
      adminUrl,
      hostUrl,
      serverIp,
      favIcon,
      logo,
      logInPageImage
    }
    let value = !isEqual(this.state.form, obj)
    this.setState({
      isVisableAddBtn: value
    })
    if (imagesData.length) {
      this.setState({
        isVisableAddBtn: true
      })
    }
  }
}

  

  openChildAccounts = () => {
    this.setState({
      childAccModal: true
    })
  }
  onCloseC_Modal = () => {
    this.setState({
      childAccModal: false
    })
  }
  render () {
    const { form, apiResponse, childAccModal } = this.state
    if (apiResponse) {
      return (
        <>
          {childAccModal && (
            <CustomDialog
              themeColors={this.props.themecolors}
              visable={false}
              maxWidth={'sm'}
              title={"Copy Attributes To Child's Account"}
              onClose={this.onCloseC_Modal}
              isButtonVisable={false}
              draggable={true}
              bodyPadding={0}
              fullWidth
            >
              <ChildBranding
                onClose={this.onCloseC_Modal}
                {...this.state}
                {...this.props}
              />
            </CustomDialog>
          )}
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  md={this.props.updateOtherServerSetting ? 4 : 3}
                  sm={6}
                >
                  <TextField
                    id='serviceTitle'
                    label={this.props.translate('serviceTitle')}
                    type='text'
                    placeholder=''
                    value={form.serviceTitle || ''}
                    onChange={e => this.handleChange('serviceTitle', e)}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                    InputProps={{
                      readOnly:
                        this.props.logInUser.userType === -1 ? false : true
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={this.props.updateOtherServerSetting ? 4 : 3}
                  sm={6}
                >
                  <TextField
                    id='footerText'
                    label={this.props.translate('footerText')}
                    type='text'
                    placeholder=''
                    value={this.state.form.footerText || ''}
                    onChange={e => this.handleChange('footerText', e)}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                    InputProps={{
                      readOnly:
                        this.props.logInUser.userType === -1 ? false : true
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={this.props.updateOtherServerSetting ? 4 : 3}
                  sm={6}
                >
                  <TextField
                    id='privacyPolicyLink'
                    label={this.props.translate('privacyPolicyLink')}
                    type='text'
                    placeholder=''
                    value={form.privacyPolicyLink || ''}
                    onChange={e => this.handleChange('privacyPolicyLink', e)}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                    InputProps={{
                      readOnly:
                        this.props.logInUser.userType === -1 ? false : true
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={this.props.updateOtherServerSetting ? 4 : 3}
                  sm={6}
                >
                  <TextField
                    id='termsLink'
                    label={this.props.translate('termsLink')}
                    type='text'
                    placeholder=''
                    value={form.termsLink || ''}
                    onChange={e => this.handleChange('termsLink', e)}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                    InputProps={{
                      readOnly:
                        this.props.logInUser.userType === -1 ? false : true
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={this.props.updateOtherServerSetting ? 4 : 3}
                  sm={6}
                >
                  <TextField
                    id='hostUrl'
                    label={this.props.translate('hostUrl')}
                    type='text'
                    placeholder=''
                    value={form.hostUrl || ''}
                    onChange={e => this.handleChange('hostUrl', e)}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                    InputProps={{
                      readOnly:
                        this.props.logInUser.userType === -1 ? false : true
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={this.props.updateOtherServerSetting ? 4 : 3}
                  sm={6}
                >
                  <TextField
                    id='adminUrl'
                    label={this.props.translate('adminUrl')}
                    type='text'
                    placeholder=''
                    value={form.adminUrl || ''}
                    onChange={e => this.handleChange('adminUrl', e)}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                    InputProps={{
                      readOnly:
                        this.props.logInUser.userType === -1 ? false : true
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={this.props.updateOtherServerSetting ? 4 : 3}
                  sm={6}
                >
                  <TextField
                    id='serverIp'
                    label={this.props.translate('serverIp')}
                    type='text'
                    placeholder=''
                    value={form.serverIp || ''}
                    onChange={e => this.handleChange('serverIp', e)}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                    InputProps={{
                      readOnly:
                        this.props.logInUser.userType === -1 ? false : true
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  md={this.props.updateOtherServerSetting ? 4 : 3}
                  sm={6}
                >
                  <input
                    type='file'
                    name='pic'
                    alt='logo'
                    accept='image/*'
                    onChange={e => this.uploadLogoImage('logo', e) }
                  ></input>
                  <TextField
                    type='text'
                    helperText={this.props.translate('logoSuggestion')}
                    //placeholder=""
                    value={form.logo}
                    error={this.state.vaildelogoFile}
                    // onChange={(e) => this.handleChange('logo', e)}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                    InputProps={{
                      readOnly:
                        this.props.logInUser.userType === -1 ? false : true
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={this.props.updateOtherServerSetting ? 4 : 3}
                  sm={6}
                >
                  <input
                    type='file'
                    name='pic'
                    alt='favIcon'
                    accept='image/*'
                    onChange={e =>  this.uploadLogoImage('favIcon', e)}
                    // onChange={e => process.env.REACT_APP_SERVER_LOCAL === true  ?  this.uploadLogoImage('favIcon', e): this.uploadImage('favIcon', e)}
                  ></input>
                  <TextField
                    helperText={this.props.translate('favIconSuggestion')}
                    type='text'
                    error={this.state.vaildefavIconFile}
                    value={form.favIcon}
                    // onChange={(e) => this.handleChange('favIcon', e)}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                    InputProps={{
                      readOnly:
                        this.props.logInUser.userType === -1 ? false : true
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={this.props.updateOtherServerSetting ? 4 : 3}
                  sm={6}
                >
                  <input
                    type='file'
                    name='pic'
                    alt='logInPageImage'
                    accept='image/*'
                    onChange={e =>  this.uploadLogoImage('logInPageImage', e) }
                  ></input>
                  <TextField
                    helperText={this.props.translate(
                      'logInPageImageSuggestion'
                    )}
                    type='text'
                    error={this.state.vaildeLogInPageImageFile}
                    value={form.logInPageImage}
                    // onChange={(e) => this.handleChange('logInPageImage', e)}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                    InputProps={{
                      readOnly:
                        this.props.logInUser.userType === -1 ? false : true
                    }}
                  />
                </Grid>
              </Grid>

              <br />
              {this.props.logInUser.userType === -1 && (
                <>
                  <Button
                    variant='contained'
                    onClick={this.formReset}
                    style={{ marginLeft: 8, marginRight: 8 }}
                  >
                    {' '}
                    {this.props.translate('resetButton')}
                  </Button>
                  <Button
                    variant='contained'
                    disabled={!this.state.isVisableAddBtn}
                    // onClick={() => process.env.REACT_APP_SERVER_LOCAL  === true ?  this.finalFormSubmit() : this.formSubmit()}
                    onClick={() => process.env.REACT_APP_SERVER_LOCAL  === "true" ?  this.finalFormSubmit() : this.formSubmit()}
                  >
                    {' '}
                    {this.props.translate('sharedSave')}
                  </Button>
                  {this.props.updateOtherServerSetting && (
                    <Button
                      style={{ marginLeft: 8, marginRight: 8 }}
                      title={
                        this.state.defaultOptions.length
                          ? this.props.translate('copyAttributes')
                          : 'No Child Accounts'
                      }
                      variant='contained'
                      disabled={!this.state.defaultOptions.length}
                      onClick={() => this.openChildAccounts()}
                    >
                      {' '}
                      {this.state.defaultOptions.length
                        ? this.props.translate('copyAttributes')
                        : 'No Child Accounts'}
                    </Button>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </>
      )
    } else {
      return <Loader defaultStyle />
    }
  }
}
const Branding = withStyles(styles)
export default Branding(branding)
