import 'date-fns'
import React, { Component, Fragment } from 'react'
import SimpleModalWrapped from '../common/Modal'
import TextField from '../common/TextField'
import { withStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import InputAdornment from '@material-ui/core/InputAdornment'
import RefreshIcon from '@material-ui/icons/Refresh'
import EditIcon from '@material-ui/icons/Place'
import CrossIcons from '@material-ui/icons/Remove'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import AsyncSelect from '../common/asyncSelect'
import Button from '../common/Button'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers'
import Grid from '@material-ui/core/Grid'
import { withLocalize } from 'react-localize-redux'
import SingleSelect from '../common/SingleSelect'
import Checkbox from '../common/Checkbox'
import { timeZonesTypes } from './../Attributes/timezone'
import FormHelperText from '@material-ui/core/FormHelperText';

import {
  mapLayerTypes,
  coordinateFormatTypes,
  ownerUserType,
  serviceProviderUserType,
  resellerUserType,
  distributorUserType,
  adminUserType,
  accountAdminType,
  checkUnitsWithStandardType,
  measurementStandard,
  currencies
} from './userDataTypes'
import './usersModal.scss'
import { checkPrivileges } from '../../Helpers'
import { connect } from 'react-redux'
import Divider from '@material-ui/core/Divider';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

let themecolors
const mapStateToProps = (state, ownProps) => {
  themecolors = state.themecolors
  return {
    themecolors: state.themecolors
  }
}

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  },
  button: {
    margin: theme.spacing(1)
  },
  grid: {
    width: '60%'
  },
  label: {
    marginTop: -4
  },
  shrink: {
    marginTop: 0
  },
  focused: {
    marginTop: 0
  },
  margin: {
    margin: theme.spacing(1)
  },
  cssLabel: {
    '&$cssFocused': {
      color: themecolors[500]
    }
  },
  cssFocused: {},
  cssUnderline: {
    '&:after': {
      borderBottomColor: themecolors[500]
    }
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: themecolors[500]
    }
  },
  notchedOutline: {},
  input: {
    padding: '12px 14px 12px'
  }
})
class addUserModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      at_modal_visable: false,
      resultMessage: '',
      showButton:this.props.editOption ? true: false,
    }
    this.searchFromZoneList = this.searchFromZoneList.bind(this)
  }

  async searchFromZoneList (inputValue) {
    if (!inputValue) {
      return []
    }

    let result = timeZonesTypes.filter(i =>
      i.key.toLowerCase().includes(inputValue.toLowerCase())
    )

    return result.map(item => {
      return { id: item.key, key: item.key, label: item.key, value: item.key }
    })
  }
  searchAccountsList = async inputValue => {
    if (!inputValue) {
      return []
    }
    let fetchapi = `/api/accounts/all?accountId=${this.props.logInUser.accountId}&search=${inputValue}&limit=100`
    if (this.props.logInUser.userType === -1) {
      fetchapi = `/api/accounts/all?accountId=${this.props.logInUser.accountId}&all=true&search=${inputValue}&limit=100`
    }
    let response = await fetch(fetchapi, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
    let json = await response.json()
    let result =
      (await json.data) &&
      json.data.map(item => ({
        ...item,
        label: item.label || item.name,
        value: item.id
      }))
    if (result.length === 0) {
      this.setState({
        resultMessage: 'No option found',
      })
    } else {
      this.setState({
        resultMessage: '',
      })
    }

    if (json.hasNext) {
      result.push({
        value: null,
        isDisabled: true,
        label: 'Search for more.'
      })
    }
    return result
  }
  searchUsersList = async inputValue => {
    if (!inputValue) {
      return []
    }
    let fetchapi = `/api/users/list?accountId=${this.props.form.accountId}&search=${inputValue}&limit=100`
    // if (this.props.logInUser.userType === -1) {
    //   fetchapi = `/api/users/list?userId=${this.props.logInUser.accountId}&all=true&search=${inputValue}&limit=100`
    // }
    let response = await fetch(fetchapi, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
    let json = await response.json()
    let result =
      (await json.data) &&
      json.data.map(item => ({
        ...item,
        label: item.label || item.name,
        value: item.id
      }))
    if (result.length === 0) {
      this.setState({
        resultMessage: 'No option found'
      })
    } else {
      this.setState({
        resultMessage: ''
      })
    }

    if (json.hasNext) {
      result.push({
        value: null,
        isDisabled: true,
        label: 'Search for more.'
      })
    }
    return result
  }
  toggleRoles = (e) =>{
    this.setState({
      showButton:!this.state.showButton
    }) 
    return this.props.handleChange('roleId')(e)

  }

  render () {
    let userTypeArray = []
    if (this.props.userType) {
      let { userType } = this.props
      if (userType === -1) {
        userTypeArray = ownerUserType
      } else if (userType === 2) {
        userTypeArray = adminUserType
      } else if(userType === 3){
        userTypeArray = accountAdminType
      } else if (userType === 4) {
        userTypeArray = serviceProviderUserType
      } else if (userType === 5) {
        userTypeArray = resellerUserType
      } else if (userType === 6) {
        userTypeArray = distributorUserType
      } else {
        userTypeArray = []
      }
    }
    let defaultZones = []
    timeZonesTypes.map((item, i) => {
      // if (i <= 9) {
      defaultZones.push({
        id: item.key,
        key: item.key,
        label: item.label,
        value: item.key
      })
      // }
    })
    const { classes } = this.props
    const selectMe = element => {
      var doc = document,
        text = element.target,
        range,
        selection
      if (doc.body.createTextRange) {
        range = document.body.createTextRange()
        range.moveToElementText(text)
        range.select()
      } else if (window.getSelection) {
        selection = window.getSelection()
        range = document.createRange()
        range.selectNodeContents(text)
        selection.removeAllRanges()
        selection.addRange(range)
      }
    }

    return (
      <Fragment>
        <SimpleModalWrapped
          {...this.props}
          visable={true}
          isButtonVisable={true}
          isNotShowAttributesBtn={true}
          title={this.props.addOption ? 'sharedRequired' : ''}
          formSubmit={this.props.formSubmit}
          addAttributes={this.addAttributes}
          modalControle={this.props.modalControle}
          buttonText={this.props.buttonText}
          btnclosetext='sharedBack'
          notVisableBackbtn={this.props.notVisableBackbtn}
          isVisableBtn={this.props.isVisableUserBtn}
          content={
            <Fragment>
              {!this.props.editOption && (<>
                <Grid container spacing={2}>
                <Grid item md={3} sm={6} xs={12}>
                      <TextField
                          id='userType'
                          select
                          label={this.props.translate('userType')}
                          value={this.props.form.userType || ''}
                          onChange={this.props.handleChange('userType')}
                          SelectProps={{
                            MenuProps: {
                              className: classes.menu
                            }
                          }}
                          margin='dense'
                          fullWidth
                          // helperText={this.props.roleTypes.length ? <span onClick={this.toggleRoles} style={{cursor: 'pointer'}}><b>Select Custom Role</b></span> : ''}
                          required
                        >
                          <MenuItem value=''>
                            <em>{this.props.translate('none')}</em>
                          </MenuItem>
                          {userTypeArray.length &&
                            userTypeArray.map(option => (
                              <MenuItem key={option.key} value={option.key}>
                                {this.props.translate(option.name)}
                              </MenuItem>
                            ))}
                        </TextField>
                      </Grid>
                </Grid>
              </>)}
              {this.props.form.userType > 2 ?  
              (<><h4>{this.props.translate('accountDetails')}</h4>
              <Grid container spacing={2}>
               <Grid item md={3} sm={6} xs={12}>
                <TextField
                  id='name'
                  label={this.props.translate('accountTitle')}
                  type='text'
                  placeholder=''
                  value={this.props.form.accountName}
                  onChange={this.props.handleChange('accountName')}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                  required
                  InputProps={{
                    readOnly:
                      !this.props.updateOption || checkPrivileges('userUpdate')
                        ? false
                        : true
                  }}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <TextField
                  id='description'
                  label={this.props.translate('sharedDescription')}
                  type='text'
                  placeholder=''
                  value={this.props.form.accountDescription || ''}
                  onChange={this.props.handleChange('accountDescription')}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                  InputProps={{
                    readOnly:
                      !this.props.updateOption || checkPrivileges('userUpdate')
                        ? false
                        : true
                  }}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <TextField
                  id='email'
                  error={this.props.emailPattern}
                  label={this.props.translate('accountEmail')}
                  type='email'
                  placeholder=''
                  value={this.props.form.accountEmail}
                  onChange={this.props.handleChange('accountEmail')}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                  required
                  InputProps={{
                    readOnly:
                      !this.props.updateOption || checkPrivileges('userUpdate')
                        ? false
                        : true
                  }}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <TextField
                  id='contact'
                  label={this.props.translate('contact')}
                  type='text'
                  placeholder=''
                  value={this.props.form.accountContact}
                  onChange={this.props.handleChange('accountContact')}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                  InputProps={{
                    readOnly:
                      !this.props.updateOption || checkPrivileges('userUpdate')
                        ? false
                        : true
                  }}
                />
               </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <TextField
                        id='reportInterval'
                        label={this.props.translate('reportInterval')}
                        type='number'
                        placeholder=''
                        disabled = {this.props?.logInUser?.userType !== -1}
                        value={this.props.form.reportInterval || 0}
                        onChange={this.props.handleChange('reportInterval')}
                        variant='outlined'
                        margin='dense'
                        fullWidth
                      />
                    </Grid>

                    <Grid item md={3} sm={6} xs={12}>
                    <TextField
                        id='historyInterval'
                        label={this.props.translate('historyInterval')}
                        type='number'
                        placeholder=''
                        disabled = {this.props?.logInUser?.userType !== -1}
                        value={this.props.form.historyInterval || 0}
                        onChange={this.props.handleChange('historyInterval')}
                        variant='outlined'
                        margin='dense'
                        fullWidth
                      />
                    </Grid>



              </Grid> </>)
            :''}

            {/* {this.props.form.userType && (<> */}
              <h4>{this.props.translate('userDetail')}</h4>
              <Grid container spacing={2}>
                <Grid item md={3} sm={6} xs={12}>
                  <TextField
                    id='name'
                    label={this.props.translate('sharedName')}
                    type='text'
                    placeholder=''
                    value={this.props.form.name}
                    onChange={this.props.handleChange('name')}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <TextField
                    id='email'
                    error={this.props.duplicateEntry || this.props.emailPattern}
                    label={this.props.translate('userEmail')}
                    type='text'
                    placeholder=''
                    value={this.props.form.email}
                    onChange={this.props.handleChange('email')}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                    required
                  />
                </Grid>
                {!this.props.editOption && (
                  <>
                    <Grid item md={3} sm={6} xs={12}>
                      <TextField
                        id='password'
                        label={this.props.translate('userPassword')}
                        type='password'
                        placeholder=''
                        value={this.props.form.password}
                        onChange={this.props.handleChange('password')}
                        variant='outlined'
                        margin='dense'
                        fullWidth
                        required
                      />
                      {/* <CheckCircleIcon style={{ color: "green" }} />
                <CancelIcon/> */}
                  <FormHelperText >
                  <div style={{display:"flex", alignItems:"center"}}>
                    {this.props.lengthCheck?
                      <CheckCircleIcon style={{ color: "green", fontSize: 14}}/> :
                      <CancelIcon style={{ color: "red",fontSize: 14 }}/>}
                      <span style={{marginLeft:"5px"}}>{this.props.translate("Length must be atleast 8 characters")}</span>
                    </div> 
                  <div style={{display:"flex", alignItems:"center"}}>
                  {this.props.numberCheck?
                    <CheckCircleIcon style={{ color: "green", fontSize: 14}}/> :
                    <CancelIcon style={{ color: "red",fontSize: 14 }}/>}
                    <span style={{marginLeft:"5px"}}>{this.props.translate("Must contain atleast one Number")}</span>
                    </div> 
                  <div style={{display:"flex", alignItems:"center"}}>
                  {this.props.upperCaseCheck?
                    <CheckCircleIcon style={{ color: "green", fontSize: 14}}/> :
                    <CancelIcon style={{ color: "red",fontSize: 14 }}/>}
                    <span style={{marginLeft:"5px"}}>{this.props.translate("Must contain atleast one Uppercase letter")}</span>
                    </div> 
                  <div style={{display:"flex", alignItems:"center"}}>
                  {this.props.lowerCaseCheck?
                    <CheckCircleIcon style={{ color: "green", fontSize: 14}}/> :
                    <CancelIcon style={{ color: "red",fontSize: 14 }}/>}
                    <span style={{marginLeft:"5px"}}>{this.props.translate("Must contain atleast one Lowercase letter")}</span>
                    </div> 
                  <div style={{display:"flex", alignItems:"center"}}>
                  {this.props.characterCheck?
                    <CheckCircleIcon style={{ color: "green", fontSize: 14}}/> :
                    <CancelIcon style={{ color: "red",fontSize: 14 }}/>}
                    <span style={{marginLeft:"5px"}}>{this.props.translate("Must contain atleast one Special character (!@#$%^&*(),.?:{}|<></>)")}</span>
                    </div> 
                </FormHelperText>
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                      <TextField
                        id='confirmPassword'
                        label={this.props.translate('confirmPassword')}
                        type='password'
                        error={
                          this.props.form.password ===
                          this.props.form.confirmPassword
                            ? false
                            : true
                        }
                        placeholder=''
                        value={this.props.form.confirmPassword}
                        onChange={this.props.handleChange('confirmPassword')}
                        variant='outlined'
                        margin='dense'
                        fullWidth
                        required
                      />
                        <FormHelperText >
                      <div style={{display:"flex", alignItems:"center"}}>
                        {this.props.confirmPasswordCheck?
                          <CheckCircleIcon style={{ color: "green", fontSize: 14}}/> :
                          <CancelIcon style={{ color: "red",fontSize: 14 }}/>}
                          <span style={{marginLeft:"5px"}}>{this.props.translate("Both Passwords must be Equal")}</span>
                        </div> 
                        </FormHelperText>
                    </Grid>
                    {this.props.userType === -1 && (
                      <>
                        <Grid item sm={6} md={3} xs={12} style={{ zIndex: 99 }}>
                          <AsyncSelect
                            isMulti={false}
                            value={this.props.accountSelectedData}
                            {...this.props}
                            selectName='accountId'
                            placeholder={this.props.translate('searchAccount')}
                            resultMessage={this.state.resultMessage}
                            defaultOptions={this.props.defaultAccountsList}
                            handleChange={this.props.timezone_handleChange}
                            loadOptions={this.searchAccountsList.bind()}
                          />
                        </Grid>
                          <Grid item sm={6} md={3} xs={12} style={{ zIndex: 99 }}>
                            <AsyncSelect
                              isDisabled = {this.props.loadingUser ? true : false}
                              isMulti={false}
                              value={this.props.userSelectedData}
                              {...this.props}
                              selectName='parentId'
                              placeholder={this.props.translate('searchUsers')}
                              resultMessage={this.state.resultMessage}
                              defaultOptions={this.props.defaultUsersList}
                              handleChange={this.props.timezone_handleChange}
                              loadOptions={this.searchUsersList.bind()}
                            />
                          </Grid>
                      
                      </>
                    )}
                  </>
                )}
                {/* {!this.props.editOption && (
                  <>
                    <Grid item md={3} sm={6} xs={12}>
                      <TextField
                        id='userType'
                        select
                        label={this.props.translate('userType')}
                        value={this.props.form.userType || ''}
                        onChange={this.props.handleChange('userType')}
                        SelectProps={{
                          MenuProps: {
                            className: classes.menu
                          }
                        }}
                        margin='dense'
                        fullWidth
                        // helperText={this.props.roleTypes.length ? <span onClick={this.toggleRoles} style={{cursor: 'pointer'}}><b>Select Custom Role</b></span> : ''}
                        required
                      >
                        <MenuItem value=''>
                          <em>{this.props.translate('none')}</em>
                        </MenuItem>
                        {userTypeArray.length &&
                          userTypeArray.map(option => (
                            <MenuItem key={option.key} value={option.key}>
                              {this.props.translate(option.name)}
                            </MenuItem>
                          ))}
                      </TextField>
                    </Grid>
                  </>
                )} */}
                {!this.state.showButton && this.props.roleTypes.length ?(
                  <Grid item md={3} sm={6} xs={12} style={{display:'flex', alignItems:'center'}}>
                      <span onClick={(e)=>this.toggleRoles(e)} style={{cursor: 'pointer'}}>
                       <b>Select Custom Role</b></span>
                  </Grid>
                ): '' }
                  {checkPrivileges('role') && this.state.showButton && (
                    <Grid item md={3} sm={6} xs={12}>
                       <TextField
                            id='role'
                            select
                            label={this.props.translate('Role')}
                            value={this.props.form.roleId || ''}
                            onChange={this.props.handleChange('roleId')}
                            SelectProps={{
                              MenuProps: {
                                className: classes.menu
                              }
                            }}
                            margin='dense'
                            fullWidth
                          >
                            {/* {!this.props.editOption && 
                              (<MenuItem key={'defaultRole'} value={'defaultRole'} >
                                <em>{this.props.translate('default')}</em>
                              </MenuItem>
                              )}
                            {!this.props.editOption && <Divider style={{background:'black'}}/> } */}
                            
                          {this.props.roleTypes.length ?
                            this.props.roleTypes.map(option => (
                              <MenuItem key={option.id} value={option.id}>
                              {this.props.translate(option.name)}
                              </MenuItem>
                            )) : <MenuItem value=''>
                            <em>{this.props.translate('none')}</em>
                            </MenuItem>}
                      </TextField> 
                        {!this.props.editOption ? (<span onClick={(e)=>this.toggleRoles(e) } style={{cursor: 'pointer'}}><b>Use Default Role</b></span>): ''}
                  </Grid>
                )}
             </Grid>
              <h4>{this.props.translate('sharedPreferences')}</h4>
              <Grid container spacing={2}>
                <Grid item md={3} sm={6} xs={12}>
                  <TextField
                    id='phone'
                    label={this.props.translate('sharedPhone')}
                    type='text'
                    placeholder=''
                    value={this.props.form.phone}
                    onChange={this.props.handleChange('phone')}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                {/* {this.props.editOption && (
                  <Grid item md={3} sm={6} xs={12}>
                    <TextField
                      id='map'
                      select
                      label={this.props.translate('mapTitle')}
                      value={this.props.form.map || ''}
                      onChange={this.props.handleChange('map')}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu
                        }
                      }}
                      margin='dense'
                      fullWidth
                    >
                      <MenuItem value=''>
                        <em>{this.props.translate('none')}</em>
                      </MenuItem>

                      {mapLayerTypes.map(option => (
                        <MenuItem key={option.key} value={option.key}>
                          {this.props.translate(option.name)}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                )} */}
                <Grid item md={3} sm={6} xs={12}>
                  <TextField
                    id='coordinateFormat'
                    select
                    label={this.props.translate('CoordinateFormat')}
                    value={this.props.form.coordinateFormat || ''}
                    onChange={this.props.handleChange('coordinateFormat')}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu
                      }
                    }}
                    margin='dense'
                    fullWidth
                  >
                    <MenuItem value=''>
                      <em>{this.props.translate('none')}</em>
                    </MenuItem>
                    {coordinateFormatTypes.map(option => (
                      <MenuItem key={option.key} value={option.key}>
                        {this.props.translate(option.name)}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item md={3} sm={6} xs={12}>
                  <TextField
                    id='map'
                    select
                    label={this.props.translate('mapTitle')}
                    value={this.props.form.map || ''}
                    onChange={this.props.handleChange('map')}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu
                      }
                    }}
                    margin='dense'
                    fullWidth
                  >
                    <MenuItem value=''>
                      <em>{this.props.translate('none')}</em>
                    </MenuItem>
                    {mapLayerTypes.map(option => (
                      <MenuItem key={option.key} value={option.key}>
                        {this.props.translate(option.name)}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                {/* <Grid item md={3} sm={6} xs={12}>
                  <TextField
                    id='poiLayer'
                    label={this.props.translate('mapPoiLayer')}
                    type='text'
                    placeholder=''
                    value={this.props.form.poiLayer}
                    onChange={this.props.handleChange('poiLayer')}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid> */}
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={3} sm={6} xs={12}>
                  <TextField
                    id='latitude'
                    type='number'
                    label={this.props.translate('positionLatitude')}
                    placeholder=''
                    value={this.props.form.latitude || ''}
                    onChange={this.props.handleChange('latitude')}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <TextField
                    id='longitude'
                    type='number'
                    label={this.props.translate('positionLongitude')}
                    value={this.props.form.longitude || ''}
                    onChange={this.props.handleChange('longitude')}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <TextField
                    id='zoom'
                    label={this.props.translate('serverZoom')}
                    type='number'
                    placeholder=''
                    value={this.props.form.zoom}
                    error={
                      this.props.form.zoom > 19 || this.props.form.zoom < 3
                        ? true
                        : false
                    }
                    helperText='Enter Value 3 to 18'
                    onChange={this.props.handleChange('zoom')}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <Tooltip title='Add Location'>
                    <IconButton onClick={this.props.handleClickShowPostion}>
                      {<EditIcon />}
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
              <h4>{this.props.translate('sharedPermissions')}</h4>
              <Grid container spacing={2}>
                <Grid item md={3} sm={6} xs={12}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      error={false}
                      margin='dense'
                      label={this.props.translate('userExpirationTime')}
                      variant='outlined'
                      fullWidth
                      minDate={new Date()}
                      value={
                        this.props&&this.props.form&&this.props.form.expirationTime
                          ? new Date(
                              `${this.props.form.expirationTime}`
                            ).toString()
                          : null
                      }
                      onChange={this.props.handleChange('expirationTime')}
                      classes={classes}
                      InputProps={{
                        classes: {
                          root: classes.cssOutlinedInput,
                          input: classes.input,
                          focused: classes.cssFocused,
                          notchedOutline: classes.notchedOutline
                        },
                        endAdornment: (
                          <InputAdornment position='end'>
                            <Tooltip title='Set No Limit'>
                              <IconButton
                                onClick={e => this.props.setExpirationTime(e)}
                              >
                                {<CrossIcons />}
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        )
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.label,
                          focused: classes.focused,
                          shrink: classes.shrink
                        }
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  {this.props.form.expirationTime && (
                    <Tooltip title='Set No Limit'>
                      <a
                        href='javascript:void(0)'
                        onClick={e => this.props.setExpirationTime(e)}
                      >
                        no limit
                      </a>
                    </Tooltip>
                  )}
                </Grid>
                {this.props.form &&
                  this.props.form.userType &&
                  this.props.form.userType !== 1 && (
                    <>
                      <Grid item md={3} sm={6} xs={12}>
                        <TextField
                          error={this.props.deviceLimitError}
                          id='deviceLimit'
                          label={this.props.translate('userDeviceLimit')}
                          type='number'
                          placeholder=''
                          value={this.props.form.deviceLimit}
                          onChange={this.props.handleChange('deviceLimit')}
                          variant='outlined'
                          margin='dense'
                          fullWidth
                          // helperText={this.props.logInUser.userType !==-1 ? 
                          //   `Remaining Units ${this.props.form.deviceLimit>0?this.props.deviceLimit - this.props.form.deviceLimit:this.props.deviceLimit }`
                          //     : null}
                          // helperText={
                          //   this.props.deviceLimitError
                          //     ? this.props.translate(
                          //         this.props.unitsLimitsMeaasge
                          //       )
                          //     : this.props.addOption && this.props.logInUser.userType !==-1 ? 
                          //     `Remaining Units ${this.props.form.deviceLimit>0?this.props.deviceLimit - this.props.form.deviceLimit:this.props.deviceLimit }`
                          //     : this.props.editOption && this.props.selectedUser.parentId !==1 ? 
                          //     `Remaining Units ${this.props.form.deviceLimit>0?this.props.deviceLimit - this.props.form.deviceLimit:this.props.deviceLimit }`
                          //    : null
                          // }
                          helperText={
                            this.props.deviceLimitError
                              ? this.props.translate(
                                  this.props.unitsLimitsMeaasge
                                )
                              : this.props.addOption && this.props.logInUser.userType !==-1 ? 
                              `Remaining Units ${this.props.form.deviceLimit>0?this.props.deviceLimit - this.props.form.deviceLimit:this.props.deviceLimit }`
                              : this.props.editOption && this.props.selectedUser.parentId !==1 ? 
                              `Created Units ${this.props.createdUnits}`
                             : null
                          }
                        />
                        {!this.props.deviceLimitError &&
                          this.props.editOption &&
                          this.props.form.deviceLimit !== -1 &&
                          (this.props.selectedUnitsLimits.count ||
                            this.props.selectedUnitsLimits.count === 0) && (
                            <Tooltip
                              title={this.props.translate('remainingUnits')}
                            >
                              <a>
                                {this.props.translate('remaining')} :{' '}
                                {this.props.form.deviceLimit -
                                  (this.props.selectedUnitsLimits.count +
                                    this.props.selectedUnitsLimits
                                      .reserveLimit)}
                              </a>
                            </Tooltip>
                          )}
                      </Grid>
                      {this.props.logInUser.userType !== 2 && (
                        <Grid item md={3} sm={6} xs={12}>
                          <TextField
                            id='userLimit'
                            error={this.props.userLimitError}
                            label={this.props.translate('userUserLimit')}
                            type='number'
                            placeholder=''                                                                
                            value={this.props.form.userLimit}
                            onChange={this.props.handleChange('userLimit')}
                            variant='outlined'
                            margin='dense'
                            fullWidth
                            helperText={
                              this.props.userLimitError
                                ? this.props.translate(
                                    this.props.usersLimitsMeaasge
                                  )
                                  : this.props.addOption && this.props.logInUser.userType !==-1 ? 
                                 `Remaining Users ${this.props.form.userLimit>0 ? this.props.usersLimit - this.props.form.userLimit:this.props.usersLimit }`
                                  : this.props.editOption && this.props.selectedUser.parentId !==1 ? 
                                 `Created Users ${this.props.createdUsers}`
                                 : null
                                }
                          
                            // helperText={this.props.logInUser.userType !==-1 ? 
                            //   `Remaining Users ${this.props.form.userLimit>0 ? this.props.usersLimit - this.props.form.userLimit:this.props.usersLimit }`
                            //     : null
                            // }
                            />
                          {!this.props.userLimitError &&
                            this.props.editOption &&
                            this.props.form.userLimit !== -1 &&
                            (this.props.selectedUserLimits.count ||
                              this.props.selectedUserLimits.count === 0) && (
                              <Tooltip
                                title={this.props.translate('remainingUser')}
                              >
                                <a>
                                  {this.props.translate('remaining')} :{' '}
                                  {this.props.form.userLimit -
                                    (this.props.selectedUserLimits.count +
                                      this.props.selectedUserLimits
                                        .reserveLimit)}
                                </a>
                              </Tooltip>
                            )}
                        </Grid>
                      )}
                      <Grid item md={3} sm={6} xs={12}>
                        <TextField
                          error={this.props.mailLimitError}
                          id='mailLimit'
                          label={this.props.translate('mailLimit')}
                          type='number'
                          placeholder=''
                          value={this.props.form.attributes.mailLimit || ''}
                          onChange={(e)=>this.props.changeAttribute2('mailLimit',e)}
                          variant='outlined'
                          margin='dense'
                          fullWidth
                          // helperText={
                          //   this.props.deviceLimitError
                          //     ? this.props.translate(
                          //         this.props.unitsLimitsMeaasge
                          //       )
                          //     : this.props.addOption && this.props.logInUser.userType !==-1 ? 
                          //     `Remaining Units ${this.props.form.deviceLimit>0?this.props.deviceLimit - this.props.form.deviceLimit:this.props.deviceLimit }`
                          //     : this.props.editOption && this.props.selectedUser.parentId !==1 ? 
                          //     `Created Units ${this.props.createdUnits}`
                          //    : null
                          // }
                        />
                      </Grid>
                    </>
                  )}
              </Grid>
              <h4 style={{ margin: '10px 0' }}>
                {this.props.translate('sharedAttributes')}
              </h4>
              <Grid container spacing={2}>
                {/* <Grid item sm={3} xs={12}>
                  <TextField
                    id='web.liveRouteLength'
                    label={this.props.translate('attributeWebLiveRouteLength')}
                    placeholder=''
                    type='number'
                    value={
                      (this.props.form.attributes &&
                        this.props.form.attributes['web.liveRouteLength']) ||
                      ''
                    }
                    onChange={e =>
                      this.props.changeAttribute2('web.liveRouteLength', e)
                    }
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid> */}
                {/* <Grid item sm={3} xs={12}>
                  <TextField
                    id='web.maxZoom'
                    type='number'
                    label={this.props.translate('attributeWebMaxZoom')}
                    placeholder=''
                    value={
                      (this.props.form.attributes &&
                        this.props.form.attributes['web.maxZoom']) ||
                      ''
                    }
                    onChange={e =>
                      this.props.changeAttribute2('web.maxZoom', e)
                    }
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid> */}
                {/* <Grid item sm={3} xs={12}>
                  <TextField
                    id='web.selectZoom'
                    type='number'
                    label={this.props.translate('attributeWebSelectZoom')}
                    placeholder=''
                    value={
                      (this.props.form.attributes &&
                        this.props.form.attributes['web.selectZoom']) ||
                      ''
                    }
                    onChange={e =>
                      this.props.changeAttribute2('web.selectZoom', e)
                    }
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid> */}
                <Grid item sm={3} xs={12}>
                  <SingleSelect
                    array={[]}
                    async
                    selectName='timezone'
                    isClearable
                    defaultOptions={defaultZones}
                    loadOptions={this.searchFromZoneList}
                    label={this.props.translate('sharedTimezone')}
                    value={
                      this.props.form.attributes &&
                      this.props.form.attributes['timezone']
                        ? timeZonesTypes.find(
                            t => t.key === this.props.form.attributes.timezone
                          )
                        : ''
                    }
                    handleChange={this.props.timezone_handleChange}
                    canAssign={true}
                  />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <TextField
                    id='measurementStandard'
                    select
                    label={this.props.translate('measurementStandard')}
                    value={
                      (this.props.form.attributes &&
                        this.props.form.attributes.measurementStandard) ||
                      ''
                    }
                    onChange={e =>
                      this.props.changeAttribute2('measurementStandard', e)
                    }
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu
                      }
                    }}
                    margin='dense'
                    fullWidth
                  >
                    {measurementStandard.map(option => (
                      <MenuItem key={option.key} value={option.key}>
                        {this.props.translate(option.value)}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xl={3} sm={6} md={4} xs={12} style ={{marginTop:0, maxWidth:'25%'}}>
                  <TextField
                    id='currencyLabel'
                    select
                    label={this.props.translate('currencies')}
                    value={
                      (this.props.form.attributes &&
                        this.props.form.attributes.currencyLabel) ||
                      ''
                    }
                    onChange={e =>
                      this.props.changeAttribute2('currencyLabel', e)
                    }
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu
                      }
                    }}
                    margin='dense'
                    fullWidth
                  >
                    {currencies&&currencies.map(option => (
                      <MenuItem key={option.key} value={option.value}>
                        {this.props.translate(option.key)}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xl={3} sm={6} md={4} xs={12}>
                 <Checkbox
                    checked={this.props.form.attributes &&  this.props.form.attributes.viewModel}
                    onChange={e =>this.props.changeAttribute2('viewModel', e)}
                    canAssign
                    canRemove
                    label={this.props.translate('viewModel')}
                  />
                </Grid>
                <Grid item xl={3} sm={6} md={4} xs={12}>
                 <Checkbox
                    checked={this.props.form.attributes &&  this.props.form.attributes.viewIMEI}
                    onChange={e =>this.props.changeAttribute2('viewIMEI', e)}
                    canAssign
                    canRemove
                    label={this.props.translate('viewIMEI')}
                  />
                </Grid>
                {/* {this.props.form.attributes && this.props.form.attributes.measurementStandard &&
                  <Grid item md={3} sm={6} xs={12}>
                    <TextField
                      id='distanceUnit'
                      select
                      label={this.props.translate('settingsDistanceUnit')}
                      value={
                        (this.props.form.attributes &&
                          this.props.form.attributes.distanceUnit) ||
                        ''
                      }
                      onChange={e =>
                        this.props.changeAttribute2('distanceUnit', e)
                      }
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu
                        }
                      }}
                      margin='dense'
                      fullWidth
                    >
                      {checkUnitsWithStandardType(
                        this.props.form.attributes.measurementStandard, 'distanceUnit').map(option => (
                          <MenuItem key={option.key} value={option.key}>
                            {this.props.translate(option.name)}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Grid>}
                {this.props.form.attributes && this.props.form.attributes.measurementStandard &&
                  <Grid item md={3} sm={6} xs={12}>
                    <TextField
                      id='speedUnit'
                      select
                      label={this.props.translate('settingsSpeedUnit')}
                      value={
                        (this.props.form.attributes &&
                          this.props.form.attributes.speedUnit) ||
                        ''
                      }
                      onChange={e => this.props.changeAttribute2('speedUnit', e)}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu
                        }
                      }}
                      margin='dense'
                      fullWidth
                    >
                      {checkUnitsWithStandardType(
                        this.props.form.attributes.measurementStandard, 'speedUnit').map(option => (
                          <MenuItem key={option.key} value={option.key}>
                            {this.props.translate(option.name)}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Grid>}
                {this.props.form.attributes && this.props.form.attributes.measurementStandard &&
                  <Grid item md={3} sm={6} xs={12}>
                    <TextField
                      id='volumeUnit'
                      select
                      label={this.props.translate('settingsVolumeUnit')}
                      value={
                        (this.props.form.attributes &&
                          this.props.form.attributes.volumeUnit) ||
                        ''
                      }
                      onChange={e => this.props.changeAttribute2('volumeUnit', e)}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu
                        }
                      }}
                      margin='dense'
                      fullWidth
                    >
                      {checkUnitsWithStandardType(
                        this.props.form.attributes.measurementStandard, 'volumeUnit').map(option => (
                          <MenuItem key={option.key} value={option.key}>
                            {this.props.translate(option.name)}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Grid>} */}
              </Grid>
              <Grid container spacing={2}>
                {this.props.editOption ? (
                  <Grid item md={6} sm={4} xs={6}>
                    <div className='license-card clearfix'>
                      <Grid container spacing={16}>
                        <Grid item xs={12}>
                          <label style={{ fontSize: 10 }}>
                            {this.props.translate('userToken')}
                          </label>
                          <Tooltip title={this.props.translate('getToken')}>
                            <Button
                              className='pull-right button-white'
                              onClick={this.props.generateToken}
                            >
                              {<RefreshIcon />}
                            </Button>
                          </Tooltip>
                          <Tooltip title={this.props.translate('copyLink')}>
                              <Button
                                  style={{marginRight:"5px"}}
                                  className='pull-right'
                                onClick={this.props.copyTokenToClipboard}
                              >
                                {<FileCopyOutlinedIcon />}
                              </Button>
                            </Tooltip>
                          <p onClick={e => selectMe(e)}>
                            <code style={{ wordBreak: 'break-all' }}>
                              {this.props.form.token &&
                                this.props.ServerSetting &&
                                this.props.ServerSetting.attributes &&
                                (this.props.ServerSetting.attributes.adminUrl +
                                  '/login?&' +
                                  btoa(this.props.form.token) ||
                                  '')}
                            </code>
                          </p>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                ) : (
                  <Grid item md={3} sm={6} xs={12}>
                    <TextField
                      label={this.props.translate('userToken')}
                      value={this.props.form.token || ''}
                      onChange={this.props.handleChange('Token')}
                      variant='outlined'
                      margin='dense'
                      fullWidth
                      InputProps={{
                        readOnly: true,
                        endAdornment: (
                          <InputAdornment position='end'>
                            <Tooltip title={this.props.translate('getToken')}>
                              <IconButton onClick={this.props.generateToken}>
                                {<RefreshIcon />}
                              </IconButton>
                            </Tooltip>
                            {/* <Button
                                  style={{marginRight:"5px"}}
                                  className='pull-right'
                                onClick={this.props.copyTokenToClipboard}
                              >
                                {<FileCopyOutlinedIcon />}
                              </Button> */}
                            <Tooltip title={this.props.translate('copyLink')}>
                            <IconButton  onClick={this.props.copyTokenToClipboard}>
                                {<FileCopyOutlinedIcon />}
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        )
                      }}
                    />
                    {this.props.form.token && (
                      <Tooltip title={this.props.translate('setNoToken')}>
                        <a
                          href='javascript:void(0)'
                          onClick={e => this.props.setNoToken(e)}
                        >
                          {this.props.translate('noToken')}
                        </a>
                      </Tooltip>
                    )}
                  </Grid>
                )}
              </Grid>
               {/* </>)} */}
            </Fragment>
          }
        />
      </Fragment>
    )
  }
}

export default connect(mapStateToProps)(
  withLocalize(withStyles(styles)(addUserModal))
)
