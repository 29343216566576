import React, { Component } from "react";
import moment from "moment";
import { Grid } from "@material-ui/core";
import Scrollbar from "react-scrollbars-custom";
import Button from "../common/Button";
import { GetApp } from "@material-ui/icons";
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers'
import DateFnsUtils from '@date-io/date-fns'
import SingleSelect from "../common/SingleSelect";
export default class BillingFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      from: "",
      to: "",
      isSubmitBtn: false,
      isVisible:false,
      selectedaccountId: [],
      contractNumber: [],
      status:"",
      selectAll:true
      // contractNumber:""
    };
  }
  componentWillReceiveProps(nextProps) {
    // Compare incoming props with previous props
    if ((nextProps.to=== "" || nextProps.from=== "") &&nextProps.selectedUser=== "") {
      // Props are not equal, update the state
      this.setState({
        to: '',
        from: '',
        selectedDate:"",
         selectedaccountId: [],
         contractNumber:[],
         selectAll:false

      });
    }
  }

 
  


  showDates = (e, date) => {
    const adjustedEndDate = date.endDate.clone().set({ hour: 23, minute: 59, second: 59, millisecond: 999 });
    const duration = moment.duration(date.startDate.diff(adjustedEndDate));
    const hours = duration.asHours();
    let timezone = "Asia/Riyadh";
    if (
      this.props.ServerSetting &&
      this.props.ServerSetting.attributes &&
      this.props.ServerSetting.attributes.timezone
    ) {
      timezone = this.props.ServerSetting.attributes.timezone;
    }
    if (
      this.props.logInUser &&
      this.props.logInUser.attributes &&
      this.props.logInUser.attributes.timezone
    ) {
      timezone = this.props.logInUser.attributes.timezone;
    }
    moment.tz.setDefault(timezone);
    var formatedDate = {
      from: moment(date.startDate.format("YYYY-MM-DD HH:mm")).toISOString(),
      to: adjustedEndDate.toISOString(),
    };
    let timeFormat = "HH:mm";
    if (this.props.logInUser && this.props.logInUser.twelveHourFormat) {
      timeFormat = "hh:mm A";
    }
    this.setState({
      selectedDate:
        moment(date.startDate).format("YYYY-MM-DD " + timeFormat) +
        " - " +
        adjustedEndDate.format("YYYY-MM-DD " + timeFormat),
      ...formatedDate,
    });
  };

  handleChange = (name, e) => {
      this.setState({
        contractNumber: name,
      });
      this.setState({ contractNumber: name }, () => {
        let filter = "";
        Object.entries(this.state.contractNumber).map(([key, value]) => {
          if (value) {
            filter += "&contractNumber=" + value;
            this.setState({});
          }
        });
        this.setState({
          selectedaccountId: filter,
        });
      });
  };

  validatedForm = (type) => {
     this.props.getMissingItems(this.state.selectedaccountId, this.state.to, this.state.from, this.state.status, type)
  };

  checkRequiredFields = () => {
    const { to,from,status,selectedDate } =this.state;   
    if ( to && from && status) {
        this.setState({ isVisible: true });
      }
      else {
        this.setState({ isVisible: false });
      }
  };
  render() {
    const { classes } = this.props

    const viewScreen = (
      <form style={{ padding: "16px 0 16px 10px" }}>
        {
          <>
            <Grid container className="form-group sidebar-bg-layer">
              <Grid item xs={12} style={{ padding: 10 }}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  views={['year', 'month']}
                  openTo="month"
                  format="MM/yyyy"
                  margin="dense"
                  label={this.props.translate("selectedDate")}
                  variant="outlined"
                  fullWidth
                  value={
                    this.props && this.props.selectedDate
                      ? new Date(`${this.props.selectedDate}`)
                      : null
                  }
                  onChange={this.props.handleChange("selectedDate")}
                  classes={classes}
                  InputProps={{
                    classes: {
                      // root: classes.cssOutlinedInput,
                      // input: classes.input,
                      // focused: classes.cssFocused,
                      // notchedOutline: classes.notchedOutline,
                    },
                  }}
                  InputLabelProps={{
                    classes: {
                      // root: classes.label,
                      // focused: classes.focused,
                      // shrink: classes.shrink,
                    },
                  }}
                  // error={this.props.contractEndDateError}
                  helperText={
                    this.props && this.props.contractEndDateError
                      ? "Contract end date must be after contract start date"
                      : ""
                  }
                />
                      </MuiPickersUtilsProvider>
              {/* <DateRangePicker
                  fill
                  error={this.props.timeError || false}
                  selectedDate={this.state.selectedDate}
                  label={
                    this.props.translate("reportFrom") +
                    " " +
                    this.props.translate("reportTo")
                  }
                  tab={this.props.tab}
                  onEvent={this.showDates}
             
                  futureDates={this.props.tab==="potentialOpportunity"?true:false}
                /> */}
              </Grid>
              <Grid item xs={12} style={{ padding: 10 }}>
          
                 <SingleSelect
                      array={[]}
                      async
                      selectName='selectedAccount'
                      defaultOptions={this.props.defaultAccountsList || []}
                      loadOptions={this.props.searchList}
                      label={this.props.translate('selectedAccount')}
                      value={
                        this.props.selectedAccount
                          ? {
                              key: this.props.selectedAccount,
                              id: this.props.selectedAccount,
                              label: this.props.accountLabel
                            }
                          : {
                              key: 'selectedAccount',
                              id: 'selectedAccount',
                              label: 'selectedAccount'
                            }
                      }
                      handleChange={this.props.handleChangeAccount}
                      canAssign={true}
                      placeholder="selectedAccount"
                         />
              
              </Grid>
              <Grid item xs={12}>
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      margin: 20,
                    }}
                  >
                    <Button
                      onClick={this.props.submitForm}
                      style={{
                        margin: 5,
                        height: "30px",
                        width: "60px",
                      }}
                      // disabled={!this.state.isVisible }
                    >
                    {this.props.translate("submit")}
                    </Button>
                    <Button
                      onClick={this.props.downloadInvoices}
                      style={{
                        margin: 5,
                        height: "30px",
                        width: "60px",
                      }}
                      // disabled={!this.state.isVisible }
                    >
                       <GetApp />
                    {this.props.translate("Excel")}
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </>
        }
      </form>
    );
    return (
      <div className="geofence-sidebar-list">
        {/* {!this.props.loader ? ( */}
          <Scrollbar>{viewScreen}</Scrollbar>
        {/* ) : (
          <Loader component="filter" />
        )} */}
      </div>
    );
  }
}
